import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {UserService} from "./service/user.service";
import {map} from "rxjs/operators";
import {promise} from "protractor";

@Injectable({
  providedIn: "root"
})
export class FeatureFlagGuard {

  mpopFeaturePrefix = 'mpopFeature_';
  firstElement = 0;

  constructor(private userService: UserService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.getFeatureFlags().pipe(
      map(featureFlags => {
        let canAccess: boolean = true;
        const targetFeatureName: string = route.data.featureFlag.featureName;
        let redirectUrl: string = route.data.featureFlag.redirectUrl;
        let accountId: string = route.params.accountId;
        redirectUrl = redirectUrl.replace(':accountId', accountId);
        const flagIndex: number = featureFlags
          .findIndex(flag => flag.featureName === (this.mpopFeaturePrefix + targetFeatureName));

        if (flagIndex === -1) {
          this.userService.getFeatureFlags().subscribe(
            (featureFlags) => {
              const flagIndex: number = featureFlags.findIndex(flag => flag.featureName === targetFeatureName);
              if (flagIndex === -1) {
                canAccess = false;
                window.location.href = redirectUrl;
              }
            }
          );
        } else {
          this.userService.getMpopFeature(targetFeatureName).subscribe(
            (featureFlag) => {
              //Since the endpoint returns a collection and that the feature flag is a unique index, the first element is always the one we want
              if (!featureFlag[this.firstElement].enabled) {
                canAccess = false;
                window.location.href = redirectUrl;
              }
            }
          )
        }

        return canAccess;
      })
    );
  }

  /**
   * Checks if a given feature is active, redirects to a given URL if not
   * @param featureName
   * @param redirectUrl
   */
  checkFeatureAccess(featureName, redirectUrl) {
    this.userService.getFeatureFlags().subscribe(
      (featureFlags) => {
        const flagIndex: number = featureFlags.findIndex(flag => flag.featureName === (this.mpopFeaturePrefix + featureName));
        if (flagIndex === -1) {
          this.userService.getFeatureFlags().subscribe(
            (featureFlags) => {
              const flagIndex: number = featureFlags.findIndex(flag => flag.featureName === featureName);
              if (flagIndex === -1) {
                window.location.href = redirectUrl;
              }
            }
          );
        } else {
          this.userService.getMpopFeature(featureName).subscribe(
            (featureFlag) => {
              if (!featureFlag[this.firstElement].enabled) {
                window.location.href = redirectUrl;
              }
            })
        }
      }
    );
  }

  /**
   * Boolean function to check if given feature is on
   * @param featureName
   */
  isFeatureOn(featureName: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.userService.getFeatureFlags().subscribe(
        (featureFlags) => {
          let result = false;
          const flagIndex: number = featureFlags.findIndex(flag => flag.featureName === (this.mpopFeaturePrefix + featureName));
          if (flagIndex !== -1) {
            this.userService.getMpopFeature(featureName).subscribe(
              (featureFlag) => {
                if (featureFlag[this.firstElement].enabled) {
                  result = true;
                }
                resolve(result);
              })
          } else {
            this.userService.getFeatureFlags().subscribe(
              (featureFlags) => {
                const flagIndex: number = featureFlags.findIndex(flag => flag.featureName === featureName);
                if (flagIndex !== -1) {
                  result = true;
                }
                resolve(result);
              }
            );
          }
        }
      );
    });
  }
}
